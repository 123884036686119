import { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import { fetchTemplateImageUrl } from '../utils/index';
// Providers
import { TemplatesContext } from '../../../app/templatesProvider';
import { UserContext } from '../../../app/userProvider';
import { LoadingContext } from '../../../app/loadingProvider';
// config
import { MANAGEMENT_API_IDENTIFIER } from '../../../config/env';
import { Template as TemplateType } from '../../../types/types';

/**
 * 帳票テンプレート画面の初期化状態を管理するhook
 * - テンプレートのステータスが利用可能の場合のみ、テンプレートの画像を取得する
 * - 画像が取得できなかった場合や、テンプレートが見つからなかった場合にエラーを返す
 */
export const useTemplateInitialization = () => {
  // 初期化完了を管理用のstate
  const [initialized, setInitialized] = useState(false);
  // API仕様モーダル表示用のstate
  const [open, setOpen] = useState(false);
  // ユーザーを取得
  const { user } = useContext(UserContext);
  const { getAccessTokenSilently } = useAuth0();
  // テンプレートを取得
  const templateId = useParams().template_id || '';
  const { getTemplate, initialized: templateInitialized } = useContext(TemplatesContext);
  const template = getTemplate(templateId);

  // テンプレート画像を取得するURLやエラー状態を管理するstate
  const [templateUrl, setTemplateUrl] = useState<string>('');
  const [loadTemplateError, setLoadTemplateError] = useState<boolean>(false);
  const [notFoundTemplateError, setNotFoundTemplateError] = useState<boolean>(false);

  // ローディングProviderを読込
  const { showLoading, hideLoading } = useContext(LoadingContext);

  /**
   * コンポーネント描画時の処理
   * - テンプレートのSVGを取得するためのURLを取得
   */
  useEffect(() => {
    if (!templateInitialized) return;
    if (initialized) return;
    if (loadTemplateError) return;
    if (templateUrl) return;
    if (!user || !user.api_keys.length) {
      return;
    }
    if (!template) {
      setNotFoundTemplateError(true);
      return;
    }

    const initialize = async (_template: TemplateType) => {
      // テンプレートのステータスが、利用可能の場合のみ、ローディングを表示して、テンプレート画像を取得
      if (_template.status === 'available') {
        // アクセストークンを取得
        const accessToken = await getAccessTokenSilently({
          authorizationParams: {
            audience: MANAGEMENT_API_IDENTIFIER,
          },
        });
        // 帳票テンプレート画像のURLを取得
        const url = await fetchTemplateImageUrl(templateId, accessToken);
        setTemplateUrl(url);
      }
      setInitialized(true);
    };

    if (template) {
      initialize(template).catch((err) => {
        console.error(err);
        setLoadTemplateError(true);
      });
    }
  }, [
    getAccessTokenSilently,
    hideLoading,
    initialized,
    loadTemplateError,
    showLoading,
    templateInitialized,
    template,
    templateId,
    templateUrl,
    user,
  ]);

  /**
   * モーダルを開く
   */
  const openModal = () => setOpen(true);

  /**
   * モーダルを閉じる
   */
  const closeModal = () => setOpen(false);

  return {
    closeModal,
    initialized,
    loadTemplateError,
    notFoundTemplateError,
    open,
    openModal,
    setLoadTemplateError,
    template,
    templateUrl,
    user,
  };
};
