import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
// mui
import { Stack } from '@mui/material';
// hooks
import { useTemplateInitialization } from '../hooks/useTemplateInitialization';
// components
import { ErrorContainer } from './ErrorContainer';
import { PageTitle } from './PageTitle';
import { APISpecificationMenu } from './APISpecificationMenu';
import { TemplateImage } from './TemplateImage';

/**
 * 帳票テンプレートページのContainer Component
 */
export const TemplateContainer: React.FC = () => {
  const {
    closeModal,
    loadTemplateError,
    notFoundTemplateError,
    open,
    openModal,
    setLoadTemplateError,
    template,
    templateUrl,
    user,
  } = useTemplateInitialization();

  const navigate = useNavigate();

  return (
    <>
      <ErrorContainer
        loadTemplateError={loadTemplateError}
        notFoundTemplateError={notFoundTemplateError}
        navigatorHandler={useCallback(() => navigate('/'), [navigate])}
      />
      <Stack maxWidth="sm" mx="auto" spacing={2}>
        <PageTitle template={template} error={{ loadTemplateError, notFoundTemplateError }} />
        <APISpecificationMenu
          template={template}
          user={user}
          open={open}
          openModalHandler={openModal}
          closeModalHandler={closeModal}
          error={{ loadTemplateError, notFoundTemplateError }}
        />
        <TemplateImage
          template={template}
          templateUrl={templateUrl}
          loadTemplateErrorHandler={() => setLoadTemplateError(true)}
        />
      </Stack>
    </>
  );
};
