import { createSchemaSnippet } from './createSchemaSnippet';
import { createCurlSnippet } from './createCurlSnippet';
import { Template } from '../../../types/types';
import { SnippetType } from '../types';

/**
 * スニペット名に応じたスニペット文字列を返す
 * @param template テンプレート
 * @param apiKey APIキー
 * @param snippetType スニペットの種類
 */
export const getSnippetText = (template: Template, apiKey: string, snippetType: SnippetType) => {
  if (snippetType === 'requestHeader') {
    return JSON.stringify({ 'x-api-key': '', 'content-type': '' }, null, 2);
  }

  if (snippetType === 'requestBody') {
    return JSON.stringify(createSchemaSnippet(template), null, 2);
  }
  if (snippetType === 'curl') {
    return createCurlSnippet(template, apiKey);
  }
  return '';
};
