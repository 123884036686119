import { Dashboard } from './Dashboard';
import { useDashboardInitialization, useTemplateCreateModal } from '../hooks';

/**
 * ダッシュボードページのコンテナコンポーネント
 * - ユーザー情報とテンプレート一覧の取得・表示
 * - テンプレート作成モーダルの制御
 */
export const DashboardContainer: React.FC = () => {
  const { initialized, user, templates } = useDashboardInitialization();
  const { open, showSuccessMessage, openModal, closeModal, showSuccess, hideSuccess } = useTemplateCreateModal();

  return (
    <Dashboard
      initialized={initialized}
      templates={templates}
      user={user}
      showSuccessMessage={showSuccessMessage}
      hideSuccess={hideSuccess}
      openModal={openModal}
      closeModal={closeModal}
      showSuccess={showSuccess}
      open={open}
    />
  );
};
