// mui
import { Button, Link, Stack } from '@mui/material';
import { Launch } from '@mui/icons-material';
// components
import { Dialog } from '../../../components/Dialog';
import { APISpecificationDialogBody } from './APISpecificationDialogBody';
// types
import { Template, User } from '../../../types/types';

/**
 * API仕様メニューコンポーネント
 * @param template テンプレート
 * @param user ユーザー
 * @param open モーダルの開閉状態
 * @param openModalHandler モーダルを開く関数
 * @param closeModalHandler モーダルを閉じる関数
 */
export const APISpecificationMenu: React.FC<{
  template: Template | null;
  user?: User;
  open: boolean;
  openModalHandler: () => void;
  closeModalHandler: () => void;
  error: {
    loadTemplateError: boolean;
    notFoundTemplateError: boolean;
  };
}> = ({ template, user, open, openModalHandler, closeModalHandler, error }) => {
  if (error.loadTemplateError) return null;
  if (error.notFoundTemplateError) return null;
  if (!template) return null;
  return (
    <Stack flexDirection="row" justifyContent="space-between" alignItems="center">
      <Stack flexDirection="row">
        <Button variant="contained" onClick={openModalHandler}>
          API仕様
        </Button>

        {/* API仕様モーダル */}
        <Dialog maxWidth="md" fullWidth open={open} handleClose={closeModalHandler}>
          <APISpecificationDialogBody
            template={template}
            apiKey={user?.api_keys.length ? user.api_keys[0] : '{your_api_key}'}
          />
        </Dialog>

        <Stack flexDirection="row" alignItems="flex-end" ml={2}>
          <Link
            href="https://sheetx.app/docs/api#operation/post-sheets"
            target="_blank"
            underline="none"
            aria-label="API Document"
            alignItems="center"
            fontSize="small"
          >
            APIドキュメント
            <Launch fontSize="inherit" />
          </Link>
        </Stack>
      </Stack>
    </Stack>
  );
};
