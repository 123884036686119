import { Stack } from '@mui/material';
import { SuccessMessage } from './SuccessMessage';
import { TemplateList } from './TemplateList';
import { ApiKeyList } from './ApiKeyList';
import { DashboardProps } from '../types';

/**
 * ダッシュボードのプレゼンテーショナルコンポーネント
 * - 成功メッセージ、テンプレート一覧、APIキー一覧を表示
 */
export const Dashboard: React.FC<DashboardProps> = ({
  initialized,
  templates,
  user,
  showSuccessMessage,
  hideSuccess,
  openModal,
  closeModal,
  showSuccess,
  open,
}) => {
  if (!initialized) return null;

  return (
    <Stack maxWidth="sm" mx="auto" spacing={6}>
      {showSuccessMessage && (
        <SuccessMessage message="テンプレートを作成中です。完了次第ご連絡いたします。" onClose={hideSuccess} />
      )}
      <TemplateList
        templates={templates}
        openModal={openModal}
        closeModal={closeModal}
        showSuccess={showSuccess}
        open={open}
      />
      <ApiKeyList apiKeys={user?.api_keys} />
    </Stack>
  );
};
