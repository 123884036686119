// mui components
import { Box, Container, Stack, Tab, Typography } from '@mui/material';
import { TabContext, TabList, TabPanel } from '@mui/lab';
// others
import SyntaxHighlighter from 'react-syntax-highlighter';
import { a11yDark } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import { JsonViewer } from '@textea/json-viewer';
// components
import { CopyButton } from '../../../components/CopyButton';
// hooks
import { useAPISpecificationDialog } from '../hooks/useAPISpecificationDialog';
// config
import { Template } from '../../../types/types';

/**
 * 言語ごとのスニペットを表示するダイアログのボディ部分
 * - 言語ごとのスニペットをタブで切り替える
 * - タブパネルの高さは、ダイアログの高さに合わせて自動的に調整する
 */
export const APISpecificationDialogBody: React.FC<{ template: Template; apiKey: string }> = ({ template, apiKey }) => {
  const {
    changeTab,
    createCurlSnippet,
    createSchemaSnippet,
    getSnippetText,
    tabName,
    tabPanelMaxHeight,
    tabsBarHeight,
  } = useAPISpecificationDialog({
    template,
    apiKey,
  });

  return (
    <Box>
      <TabContext value={tabName}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider', height: `${tabsBarHeight}px` }}>
          <TabList onChange={changeTab} aria-label="API snippet tabs">
            <Tab label="API schema" value="schema" />
            <Tab label="curl" value="curl" />
          </TabList>
        </Box>

        <Container
          sx={{ overflowY: 'auto', maxHeight: `${tabPanelMaxHeight}px`, paddingTop: '8px', paddingBottom: '24px' }}
        >
          <TabPanel value="schema" style={{ padding: 0 }}>
            <Stack spacing={2}>
              <Stack spacing={2}>
                <Stack flexDirection="row" justifyContent="space-between" alignItems="center">
                  <Typography variant="subtitle2">Request Header</Typography>
                  <CopyButton text={getSnippetText(template, apiKey, 'requestHeader')} />
                </Stack>
                <JsonViewer
                  className="request-schema-viewer"
                  value={{
                    'x-api-key': '',
                    'content-type': '',
                  }}
                  displaySize={false}
                  rootName={false}
                  enableClipboard={false}
                  style={{ backgroundColor: 'whitesmoke', padding: '8px' }}
                />
              </Stack>

              <Stack spacing={2}>
                <Stack flexDirection="row" justifyContent="space-between" alignItems="center">
                  <Typography variant="subtitle2">Request Body</Typography>
                  <CopyButton text={getSnippetText(template, apiKey, 'requestBody')} />
                </Stack>
                <JsonViewer
                  className="request-schema-viewer"
                  value={createSchemaSnippet(template)}
                  displaySize={false}
                  rootName={false}
                  enableClipboard={false}
                  maxDisplayLength={template.replacements.length || 30} // 置換項目数分だけ表示し、置換項目がない場合は30項目まで表示する
                  style={{ backgroundColor: 'whitesmoke', padding: '8px' }}
                />
              </Stack>
            </Stack>
          </TabPanel>
          <TabPanel value="curl" style={{ padding: 0 }}>
            <Stack spacing={2}>
              <Stack flexDirection="row" justifyContent="flex-end" alignItems="center">
                <CopyButton text={getSnippetText(template, apiKey, 'curl')} />
              </Stack>
              <SyntaxHighlighter language="bash" style={a11yDark} wrapLongLines>
                {createCurlSnippet(template, apiKey)}
              </SyntaxHighlighter>
            </Stack>
          </TabPanel>
        </Container>
      </TabContext>
    </Box>
  );
};
