import { templatesApi } from '../api';

/**
 * 帳票テンプレート画像のURLを取得
 * @param templateId テンプレートID
 * @param accessToken アクセストークン
 * @returns 帳票テンプレート画像のURL
 */
export const fetchTemplateImageUrl = async (templateId: string, accessToken: string): Promise<string> =>
  templatesApi.getTemplateImageUrl(templateId, accessToken);
