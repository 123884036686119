import { Stack, Typography } from '@mui/material';
import { Template } from '../../../types/types';

/**
 * ページタイトルを表示するコンポーネント
 * @param template テンプレート
 */
export const PageTitle: React.FC<{
  template: Template | null;
  error: {
    loadTemplateError: boolean;
    notFoundTemplateError: boolean;
  };
}> = ({ template, error }) => {
  if (error.loadTemplateError) return null;
  if (error.notFoundTemplateError) return null;
  if (!template) return null;
  return (
    <Stack flexDirection="row" justifyContent="space-between" alignItems="center">
      <Typography variant="h5" component="h2" fontWeight="bold">
        {template.name}
      </Typography>
    </Stack>
  );
};
