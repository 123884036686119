import axios, { AxiosError } from 'axios';
import { DASHBOARD_API_BASE_URL } from '../../../config/env';

export const createApiClient = (accessToken: string) => {
  const client = axios.create({
    baseURL: DASHBOARD_API_BASE_URL,
    headers: {
      Authorization: `Bearer ${accessToken}`,
      'Content-Type': 'application/json',
    },
  });

  client.interceptors.response.use(
    (response) => response,
    (error: AxiosError<{ message: string }>) => Promise.reject(error)
  );

  return client;
};
