/**
 * エラーフラグに応じたエラーメッセージを取得
 * @param loadTemplateError
 * @param notFoundTemplateError
 * @returns エラーメッセージ
 */
export const getErrorMessage = (errorFlags: { loadTemplateError: boolean; notFoundTemplateError: boolean }): string => {
  const { loadTemplateError, notFoundTemplateError } = errorFlags;
  if (!loadTemplateError && !notFoundTemplateError) return '';

  if (loadTemplateError) return 'プレビューを表示できませんでした。';
  if (notFoundTemplateError) return 'テンプレートが見つかりません。';

  return '未知のエラーが発生しました。';
};
