import { Template } from '../../../types/types';

/**
 * テンプレートのスキーマスニペットを生成
 * @param template テンプレート
 */
export const createSchemaSnippet = (template: Template) => {
  const replacementValueObj: { [key in string]: string } = {};
  template.replacements.forEach((replacement) => {
    replacementValueObj[replacement] = '';
  });

  return {
    response_type: '',
    sheets: [
      {
        template_id: '',
        page_number: 1,
        values: replacementValueObj,
      },
    ],
  };
};
