import { SHEETX_API_BASE_URL } from '../../../config/env';
import { Template } from '../../../types/types';

/**
 * 帳票生成APIを呼び出すためのCurlコマンド文字列を生成
 * @param template テンプレート
 * @param apiKey APIキー
 */
export const createCurlSnippet = (template: Template, apiKey: string) => {
  const replacementValueObj: { [key in string]: string } = {};
  template.replacements.forEach((replacement) => {
    replacementValueObj[replacement] = `●●●`;
  });
  const headers = {
    'content-type': 'application/json',
    'x-api-key': apiKey,
  };
  const body = {
    response_type: 'url',
    sheets: [
      {
        template_id: template.template_id,
        page_number: 1,
        values: replacementValueObj,
      },
    ],
  };
  const headersString = Object.entries(headers)
    .map(([key, value]) => `-H "${key}: ${value}" \\`)
    .join('\n');
  const bodyString = JSON.stringify(body, null, 2)
    .split('\n')
    .map((line) => `  ${line}`) // Indent each line of the JSON body
    .join('\n');
  return `curl -X POST \\\n${headersString}\n-d '${bodyString.trim()}' \\\n${SHEETX_API_BASE_URL}/sheets`;
};
