import { Button, Container, Stack, Typography } from '@mui/material';
import { getErrorMessage } from '../utils/getErrorMessage';

/**
 * エラーメッセージを表示するコンポーネント
 * @param loadTemplateError テンプレートの読み込みエラー
 * @param notFoundTemplateError テンプレートが見つからないエラー
 * @param navigatorHandler ナビゲーションハンドラ
 */
export const ErrorContainer: React.FC<{
  loadTemplateError: boolean;
  notFoundTemplateError: boolean;
  navigatorHandler: () => void;
}> = ({ loadTemplateError, notFoundTemplateError, navigatorHandler }) => {
  if (!loadTemplateError && !notFoundTemplateError) return null;
  return (
    <Stack direction="column" maxWidth="sm" mx="auto" spacing={4}>
      <Stack direction="column" spacing={2}>
        <Typography>{getErrorMessage({ loadTemplateError, notFoundTemplateError })}</Typography>
      </Stack>
      <Container style={{ textAlign: 'center' }}>
        <Button variant="contained" onClick={navigatorHandler}>
          ダッシュボードに戻る
        </Button>
      </Container>
    </Stack>
  );
};
