import { Stack, Typography } from '@mui/material';
import { Image } from 'mui-image';
import { Template } from '../../../types/types';

/**
 * テンプレートの画像を表示するコンポーネント
 * @param template テンプレート
 * @param templateUrl テンプレートのURL(アクセストークン付き)
 * @param loadTemplateErrorHandler テンプレート読み込みエラーハンドラ
 */
export const TemplateImage: React.FC<{
  template: Template | null;
  templateUrl: string;
  loadTemplateErrorHandler: () => void;
}> = ({ template, templateUrl, loadTemplateErrorHandler }) => {
  if (!template) return null;
  return (
    <Stack spacing={2}>
      {/* テンプレートの状態によって、表示を切替 */}
      {template.status === 'available' && templateUrl && (
        <Image
          src={`${templateUrl}`}
          showLoading
          duration={0}
          style={{ border: '1px solid black' }}
          onError={loadTemplateErrorHandler}
          errorIcon={null}
        />
      )}
      {template.status === 'processing' && (
        <Typography>
          テンプレートは現在作成中です。
          <br />
          完了次第ご連絡いたします。
        </Typography>
      )}
    </Stack>
  );
};
