import { Box, IconButton, Typography } from '@mui/material';
import { Close } from '@mui/icons-material';
import { SuccessMessageProps } from '../types';

/**
 * 成功メッセージを表示するプレゼンテーショナルコンポーネント
 */
export const SuccessMessage: React.FC<SuccessMessageProps> = ({ message, onClose }) => (
  <Box sx={{ backgroundColor: 'rgba(16, 185, 129, 0.12)' }} p={2} position="relative">
    <Typography variant="body2">{message}</Typography>
    <IconButton sx={{ position: 'absolute', right: '5px', top: '0' }} onClick={onClose}>
      <Close />
    </IconButton>
  </Box>
);
