import { createApiClient } from './client';
import type { GetTemplateUrlResponse } from '../types';

/**
 * テンプレートに関するAPIを呼び出すための関数群
 * - getTemplateImageUrl: テンプレートの画像URLを取得
 */
export const templatesApi = {
  getTemplateImageUrl: async (templateId: string, accessToken: string): Promise<string> => {
    const client = createApiClient(accessToken);
    const response = await client.post<GetTemplateUrlResponse>(`/templates/${templateId}/get-download-url`, {});
    return response.data.url;
  },
};
