import { useEffect, useState } from 'react';
import { createSchemaSnippet, createCurlSnippet, getSnippetText } from '../utils/index';
import { TabName, useAPISpecificationDialogProps } from '../types';

const dialogMarginTop = 32;
const dialogMarginBottom = 32;
const dialogTitleHeight = 64;
const tabsBarHeight = 48;

/**
 * 帳票テンプレート画面のAPI仕様ダイアログを管理するhook
 * - モーダルの表示/非表示を制御
 * - 作成完了メッセージの表示/非表示を制御
 */
export const useAPISpecificationDialog = (props: useAPISpecificationDialogProps) => {
  const { template, apiKey } = props;
  const [tabName, setTabName] = useState<TabName>('schema');
  const [tabPanelMaxHeight, setTabPanelMaxHeight] = useState<number>(0);

  /**
   * ウィンドウの高さが変更されたら、タブの高さを再計算する
   */
  useEffect(() => {
    const resize = () => {
      // タブパネルのmaxHeight = windowの高さ - ダイアログの上下のマージン - ダイアログのタイトル行の高さ - タブのバーの高さ
      const height = window.innerHeight - dialogMarginTop - dialogMarginBottom - dialogTitleHeight - tabsBarHeight;
      setTabPanelMaxHeight(height);
    };
    window.addEventListener('resize', resize);
    resize();

    return () => {
      window.removeEventListener('resize', resize);
    };
  });

  // タブを切り替える
  const changeTab = (event: React.SyntheticEvent, newTabName: TabName) => setTabName(newTabName);

  return {
    changeTab,
    createCurlSnippet,
    createSchemaSnippet,
    getSnippetText,
    tabName,
    tabPanelMaxHeight,
    tabsBarHeight,
  };
};
