import { useTemplateCreate } from '../hooks';
import { TemplateCreateForm } from './TemplateCreateForm';

/**
 * 帳票テンプレート作成フォームのコンテナコンポーネント
 * - 帳票テンプレート名、帳票イメージファイルの入力管理
 * - ファイルは10MB以下のみ許可
 */
export const TemplateCreateFormContainer: React.FC<{ onCancel: () => void; onSuccess: () => void }> = ({
  onCancel,
  onSuccess,
}) => {
  const {
    control,
    errors,
    fileInputRef,
    handleSubmit,
    handleFileChange,
    handleSizeAndOrientationSelectChange,
    onSubmit,
    truncateFileName,
    watch,
    setValue,
    clearErrors,
  } = useTemplateCreate(onSuccess);

  return (
    <TemplateCreateForm
      control={control}
      errors={errors}
      fileInputRef={fileInputRef}
      handleSubmit={handleSubmit}
      handleFileChange={handleFileChange}
      handleSizeAndOrientationSelectChange={handleSizeAndOrientationSelectChange}
      onSubmit={onSubmit}
      truncateFileName={truncateFileName}
      watch={watch}
      setValue={setValue}
      clearErrors={clearErrors}
      onCancel={onCancel}
    />
  );
};
