import { Link as RouterLink } from 'react-router-dom';
import {
  Button,
  Link,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { CopyIcon } from '../../../components/CopyIcon';
import { TemplateStatusBadge } from '../../../components/TemplateStatusBadge';
import { Dialog } from '../../../components/Dialog';
import { TemplateCreateFormContainer } from './TemplateCreateFormContainer';
import { TemplateListProps } from '../types';

/**
 * テンプレート一覧を表示するプレゼンテーショナルコンポーネント
 */
export const TemplateList: React.FC<TemplateListProps> = ({ templates, openModal, closeModal, showSuccess, open }) => (
  <Stack spacing={2}>
    <Stack flexDirection="row" justifyContent="space-between" alignItems="center">
      <Typography variant="h5" component="h2" fontWeight="bold">
        帳票テンプレート
      </Typography>
      <Button variant="contained" size="small" onClick={openModal}>
        新規作成
      </Button>
      <Dialog
        title="帳票テンプレートを作成"
        closeModalOnBackdropClick={false}
        maxWidth="xs"
        fullWidth
        open={open}
        handleClose={closeModal}
      >
        <TemplateCreateFormContainer onCancel={closeModal} onSuccess={showSuccess} />
      </Dialog>
    </Stack>
    <Stack>
      {templates && templates.length > 0 ? (
        <TableContainer style={{ border: '1px solid rgb(230, 235, 241)', borderRadius: 5, backgroundColor: 'white' }}>
          <Table aria-label="templates table" size="small">
            <TableHead>
              <TableRow sx={{ height: '64.5px' }}>
                <TableCell>名前</TableCell>
                <TableCell width="70px" align="center" style={{ paddingRight: 0 }}>
                  サイズ
                </TableCell>
                <TableCell
                  width="180px"
                  align="center"
                  style={{ paddingRight: 0 }}
                  sx={{ width: { sm: '180px', xs: '100px' } }}
                >
                  template_id
                </TableCell>
                <TableCell width="120px" align="center">
                  ステータス
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {templates.map((template) => (
                <TableRow
                  key={template.template_id}
                  sx={{
                    '&:last-child td, &:last-child th': { border: 0 },
                  }}
                >
                  <TableCell>
                    <Link component={RouterLink} to={`/templates/${template.template_id}`} underline="hover">
                      {template.name}
                    </Link>
                  </TableCell>
                  <TableCell align="center" style={{ paddingRight: 0 }}>
                    {template.size}
                    {template.orientation === 'portrait' && '縦'}
                    {template.orientation === 'landscape' && '横'}
                  </TableCell>
                  <TableCell style={{ paddingRight: 0 }}>
                    <Stack flexDirection="row" justifyContent="space-between" alignItems="center">
                      {template.template_id.substring(0, 15)}...
                      <CopyIcon text={template.template_id} />
                    </Stack>
                  </TableCell>
                  <TableCell align="center">
                    <TemplateStatusBadge status={template.status} />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <Typography>現在、テンプレートはありません。</Typography>
      )}
    </Stack>
  </Stack>
);
