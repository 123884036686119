import {
  Box,
  Button,
  Container,
  FormControl,
  TextField,
  IconButton,
  Input,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  Typography,
} from '@mui/material';
import { Close, InfoOutlined } from '@mui/icons-material';
import { Controller } from 'react-hook-form';
import { TemplateCreateFormProps } from '../types';

/**
 * 帳票テンプレート作成フォームのプレゼンテーショナルコンポーネント
 * - 帳票テンプレート名、帳票イメージファイルの入力UI
 * - ファイルは10MB以下のみ許可
 */
export const TemplateCreateForm: React.FC<TemplateCreateFormProps> = ({
  control,
  errors,
  fileInputRef,
  handleSubmit,
  handleFileChange,
  handleSizeAndOrientationSelectChange,
  onSubmit,
  truncateFileName,
  watch,
  setValue,
  clearErrors,
  onCancel,
}) => (
  <Stack component="form" noValidate onSubmit={handleSubmit(onSubmit)} direction="column" spacing={2} py={2}>
    <Container>
      <Stack direction="column" spacing={3}>
        {/* 帳票テンプレート名 */}
        <Controller
          name="templateName"
          control={control}
          rules={{
            required: '帳票テンプレート名を入力してください',
          }}
          render={({ field, fieldState }) => (
            <TextField
              {...field}
              type="text"
              label="帳票テンプレート名"
              error={fieldState.invalid}
              helperText={fieldState.error?.message}
              fullWidth
              size="small"
            />
          )}
        />
        {/* 帳票イメージ */}
        <Box mt={4} position="relative">
          {/* 帳票イメージのデータを管理するInput、デザインはカスタマイズするため、Input自体は非表示 */}
          <Input
            fullWidth
            ref={fileInputRef}
            type="file"
            size="small"
            style={{ display: 'none' }}
            onChange={handleFileChange}
            onClick={() => {
              // 同じファイルを選択した場合、onChangeが発火しないため、クリアする
              if (fileInputRef.current) {
                const input = fileInputRef.current.children[0] as HTMLInputElement;
                input.value = '';
              }
            }}
          />
          {/* 帳票イメージのInputのデザインを管理するコンポーネント */}
          <Stack direction="row" position="relative">
            {/* TextFieldを用いて、他の入力フォームのデザインと同じデザインにする */}
            <TextField
              fullWidth
              label="帳票イメージ"
              placeholder="クリックしてファイルを選択"
              value={truncateFileName(watch('sourceFile')?.name)}
              // エラーがある場合は赤枠とエラーメッセージを表示
              error={!!errors.sourceFile}
              helperText={errors.sourceFile?.message}
              size="small"
              // ラベルを左上に表示
              InputLabelProps={{ shrink: true }}
              inputProps={{ sx: { cursor: 'pointer' } }}
              // TextFieldのイベントを無効化にし、ファイル選択のイベントを発火させる
              onMouseDown={(e) => {
                e.preventDefault();
                if (fileInputRef.current) {
                  const input = fileInputRef.current.children[0] as HTMLInputElement;
                  input.click();
                }
              }}
            />
            {/* ファイル選択解除ボタン、ファイル選択済の場合のみ表示する */}
            {watch('sourceFile') && (
              <IconButton
                style={{ position: 'absolute', right: '5px' }}
                onClick={() => {
                  // 選択されているファイルをクリア
                  if (fileInputRef.current) {
                    const input = fileInputRef.current?.children[0] as HTMLInputElement;
                    input.value = '';
                    setValue('sourceFile', null);
                    // エラーをクリア
                    clearErrors('sourceFile');
                  }
                }}
              >
                <Close />
              </IconButton>
            )}
          </Stack>
          <Stack flexDirection="row" alignItems="center" mt={1} color="currentcolor">
            <InfoOutlined fontSize="small" />
            <Typography component="span" fontSize="small" ml={0.5} letterSpacing={0}>
              帳票の例やフォーマットをアップロードしてください
            </Typography>
          </Stack>
        </Box>
        {/* サイズと向き */}
        <FormControl>
          <InputLabel id="size-and-orientation-select-label">帳票サイズ</InputLabel>
          <Select
            labelId="size-and-orientation-select-label"
            id="size-and-orientation-select"
            label="帳票サイズ"
            value={`${watch('size').valueOf()},${watch('orientation').valueOf()}`}
            onChange={(e) => handleSizeAndOrientationSelectChange(e.target.value)}
            size="small"
          >
            <MenuItem value="A4,portrait">A4縦</MenuItem>
            <MenuItem value="A4,landscape">A4横</MenuItem>
            <MenuItem value="A5,portrait">A5縦</MenuItem>
            <MenuItem value="A5,landscape">A5横</MenuItem>
            <MenuItem value="A3,portrait">A3縦</MenuItem>
            <MenuItem value="A3,landscape">A3横</MenuItem>
          </Select>
        </FormControl>

        <Stack
          flexDirection={{ sm: 'row', xs: 'column-reverse' }}
          justifyContent={{ sm: 'flex-end', xs: 'flex-start' }}
          alignItems={{ sm: 'center', xs: 'center' }}
        >
          <Button variant="outlined" style={{ minWidth: '100px', margin: '8px' }} onClick={onCancel}>
            キャンセル
          </Button>
          <Button variant="contained" type="submit" style={{ minWidth: '100px' }}>
            作成
          </Button>
        </Stack>
      </Stack>
    </Container>
  </Stack>
);
