import { Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import { CopyIcon } from '../../../components/CopyIcon';
import { ApiKeyListProps } from '../types';

/**
 * APIキー一覧を表示するプレゼンテーショナルコンポーネント
 */
export const ApiKeyList: React.FC<ApiKeyListProps> = ({ apiKeys }) => (
  <Stack spacing={2}>
    <Stack flexDirection="row" justifyContent="space-between" alignItems="center">
      <Typography variant="h5" component="h2" fontWeight="bold">
        APIキー
      </Typography>
    </Stack>
    <Stack>
      {apiKeys && apiKeys.length > 0 ? (
        <TableContainer style={{ border: '1px solid rgb(230, 235, 241)', borderRadius: 5, backgroundColor: 'white' }}>
          <Table aria-label="API keys table" style={{ tableLayout: 'fixed' }} size="small">
            <TableHead>
              <TableRow sx={{ height: '64.5px' }}>
                <TableCell>APIキー</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {apiKeys.map((apiKey) => (
                <TableRow key={apiKey} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                  <TableCell style={{ wordBreak: 'break-word' }}>
                    {apiKey}
                    <CopyIcon text={apiKey} />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <Typography>テンプレートの作成完了後、表示されます。</Typography>
      )}
    </Stack>
  </Stack>
);
